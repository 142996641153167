<template>
  <router-view></router-view>
</template>

<script>
// import Dashboard from '@/components/Dashboard';

export default {
  name: 'DashboardPage',
  // components: {
  //   Dashboard,
  // },
};
</script>
